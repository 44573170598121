
.bmlogo{
  background-image: url(bmlogo.png);
  background-size: 24px;
  width:24px;
  height:24px;
  display: inline-block;
}
.bmbackground{
  background-image: url(bmbackground_home2.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  height:100%;
  width:100%;
}
